import { SERVER_URL, PAGINATION_BUTTONS_COUNT, WEB_URL, DOTHTML } from './config';

//-------TIMER----------
export const waitTimer = async function(time){
  return new Promise((resolve) => setTimeout(resolve, time));
}


//-------SHARE BUTTON-------
export const sharePlayer = function (event, player) {
  if (navigator.share) {
    navigator
      .share({
        title: `I-generated crptl-player #${player.id}`,
        text: `Here is ${player.firstName} ${player.lastName}, AI-generated NFT-player #${player.id}`,
        url: `${WEB_URL}/player${DOTHTML}#${player.id}`,
      })
      .catch((error) => {});
  } else {
    if (navigator.clipboard) {
      const shareText = `Here is ${player.firstName} ${player.lastName}, AI-generated crptl-player #${player.id}, url: ${WEB_URL}/player${DOTHTML}#${player.id}`;
      navigator.clipboard.writeText(shareText).then(
        () => {
          let newDiv = document.createElement('div');
          newDiv.innerHTML = 'Coppied to clipboard';
          newDiv.style.padding = '10px';
          newDiv.style.fontSize = '20px';
          newDiv.style.position = 'absolute';
          newDiv.style.backgroundColor = '#23454bbe';
          newDiv.style.border = '1px solid #7EE4F4';
          newDiv.style.left = event.pageX - 70 + 'px';
          newDiv.style.top = event.pageY - 70 + 'px';
          document.body.appendChild(newDiv);
          setTimeout(() => {
            newDiv.remove();
          }, 500);
        },
        (err) => {
          console.log('Failed to copy text: ', err);
        }
      );
    } else {
      alert('The Web Share API and Clipboard API are not supported on this browser.');
    }
  }
};

//-------PAGINATION-------
const addPaginationButton = function (parentEl, page, num) {
  let markup;
  if (typeof num === 'number') {
    markup = `<div class="sections-explore__pagination-item${
      num === page ? ' pagination-item--active' : ''
    }" data-goto="${num}"> <div>${num}</div>`;
  } else {
    markup = `<div class="sections-explore__pagination-dotitem"> <div>${num}</div>`;
  }
  parentEl.insertAdjacentHTML('beforeend', markup);
};

export const renderPaginationButtons = function (parentEl, pagesCount, page) {
  const pageBrakeStart = Math.floor(PAGINATION_BUTTONS_COUNT / 2 + 1);
  const pageBrakeEnd = pagesCount - Math.ceil(PAGINATION_BUTTONS_COUNT / 2 - 1);
  parentEl.innerHTML = '';
  if (pagesCount <= 1) return;
  //1. If pages <= limit
  if (pagesCount <= PAGINATION_BUTTONS_COUNT) {
    for (let i = 1; i <= pagesCount; i++) {
      addPaginationButton(parentEl, page, i);
    }
    //2.
  } else if (page <= pageBrakeStart) {
    for (let i = 1; i <= PAGINATION_BUTTONS_COUNT - 2; i++) {
      addPaginationButton(parentEl, page, i);
    }
    addPaginationButton(parentEl, page, '...');
    addPaginationButton(parentEl, page, pagesCount);
    //3.
  } else if (page > pageBrakeStart && page < pageBrakeEnd) {
    addPaginationButton(parentEl, page, 1);
    addPaginationButton(parentEl, page, '...');
    for (let i = page - pageBrakeStart + 3; i <= page - pageBrakeStart + 2 + (PAGINATION_BUTTONS_COUNT - 4); i++) {
      addPaginationButton(parentEl, page, i);
    }
    addPaginationButton(parentEl, page, '...');
    addPaginationButton(parentEl, page, pagesCount);
    //4.
  } else {
    addPaginationButton(parentEl, page, 1);
    addPaginationButton(parentEl, page, '...');
    for (let i = pagesCount - (PAGINATION_BUTTONS_COUNT - 2) + 1; i <= pagesCount; i++) {
      addPaginationButton(parentEl, page, i);
    }
  }
};

//-------PAGINATION EVENT HANDLING-------
export const addHandlerPagination = function (parentEl, state, handler) {
  const clickHandler = function(e){
    const btn = e.target.closest('.sections-explore__pagination-item');
    if (!btn) return;
    if (btn.dataset.goto === '...') return;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    const goToPage = +btn.dataset.goto;
    state.page = goToPage;
    handler();
    parentEl.removeEventListener('click', clickHandler);
  } 
  parentEl.addEventListener('click', clickHandler);
};

//-------ERRORS HANDLING-------
function errorPopupMarkup(message) {
  return `<div class="error-popup-container">
  <div class="error-popup-loader error-popup-animated"></div>
  ${message}
  </div>`;
}

export function showErrorPopup(message) {
  const errorPopup = errorPopupMarkup(message);
  setTimeout(() => {
    document.body.insertAdjacentHTML('beforeend', errorPopup);
    setTimeout(() => {
      let errorDivToRemove = document.querySelector('.error-popup-container');
      errorDivToRemove.remove();
    }, 4000);
  }, 2000);
}

//-------JOKES IN CONSOLE-------
export function crptlGreetings(jokeMessage) {
  console.log(String.raw`  
 ________/\\\\\\\\\____/\\\\\\\\\______/\\\\\\\\\\\\\____/\\\\\\\\\\\\\\\__/\\\_____________        
  _____/\\\////////___/\\\///////\\\___\/\\\/////////\\\_\///////\\\/////__\/\\\_____________       
   ___/\\\/___________\/\\\_____\/\\\___\/\\\_______\/\\\_______\/\\\_______\/\\\_____________      
    __/\\\_____________\/\\\\\\\\\\\/____\/\\\\\\\\\\\\\/________\/\\\_______\/\\\_____________     
     _\/\\\_____________\/\\\//////\\\____\/\\\/////////__________\/\\\_______\/\\\_____________    
      _\//\\\____________\/\\\____\//\\\___\/\\\___________________\/\\\_______\/\\\_____________   
       __\///\\\__________\/\\\_____\//\\\__\/\\\___________________\/\\\_______\/\\\_____________  
        ____\////\\\\\\\\\_\/\\\______\//\\\_\/\\\___________________\/\\\_______\/\\\\\\\\\\\\\\\_ 
         _______\/////////__\///________\///__\///____________________\///________\///////////////__`);

  console.log(`Hi developer!`);
  console.log(`${jokeMessage}`);
}

//-------JOKES-------
//Knock, knock. Who's there? Debugger. Debugger who? Debugger not to love these hidden messages.+
//Why did the developer quit his job? He didn't get arrays.+
//Why do programmers prefer dark mode?Because light attracts bugs!+
//I have programmed joke for you but it only works on my computer, sorry ¯\\_(ツ)_/¯++

//-------CREATING AVATARS-------
export const crptlAvatar = function (seed = '') {
  const COLORS_NB = 9;
  const saturation = 90;
  const lightness = 45;
  const hash = seed.split('').reduce((hash, char) => (hash ^ char.charCodeAt(0)) * -6, 6);
  const hueTotal = (hash % COLORS_NB) * (360 / COLORS_NB);
  const hue = hueTotal >= 0 ? 188 : 328;
  return (
    [...Array(seed ? 25 : 0)].reduce(
      (acc, e, i) =>
        hash & (1 << i % 15)
          ? acc + `<rect x="${i > 14 ? 7 - ~~(i / 5) : ~~(i / 5)}" y="${i % 5}" width="1" height="1"/>`
          : acc,
      `<svg viewBox="-1.5 -1.5 8 8" xmlns="http://www.w3.org/2000/svg" fill="hsl(${hue} ${saturation}% ${lightness}%)" style="filter: drop-shadow(0px 0px 5px hsl(${hue}, 30%, ${lightness}%))">`
    ) + '</svg>'
  );
};

//-------CHANGING RESPONCE FROM SERVER-------
export const reformatPlayerObjectFromServer = function (playerFromServer) {
  const badgesArray = Object.values(playerFromServer.badges);
  const paddedId = String(playerFromServer.id).padStart(4, '0');
  const player = {
    total: playerFromServer.total,
    rating: playerFromServer.rating,
    badges: badgesArray,
    id: playerFromServer.id,
    faceUrl: `${SERVER_URL}/assets/faces/256/player-${paddedId}.png`,
    displayUrl: `${SERVER_URL}/assets/cards/1000/player-${paddedId}.png`,
    firstName: playerFromServer.first_name,
    lastName: playerFromServer.last_name,
    skill: playerFromServer.skill,
    physical: playerFromServer.physical,
    pace: playerFromServer.pace,
    position: playerFromServer.position,
    birthday: playerFromServer.birthday,
    faceUrlOnBlockchain: playerFromServer.face_url,
  };
  return player;
};

//-------SHORTEN ACCOUNT-------
export const shortenAddress = function (addres) {
  const prefix = addres.substring(0, 6);
  const suffix = addres.substring(addres.length - 4);
  const maskedAddress = `${prefix}****${suffix}`;
  return maskedAddress;
};
